import { Button } from "@mui/material";
import { Form } from "@remix-run/react";
import { useEffect, useRef } from "react";

export default function Login() {
  const ref = useRef<HTMLFormElement>(null);
  useEffect(() => {
    ref.current?.submit();
  }, []);
  return (
    <Form action="/auth/auth0" method="post" ref={ref}>
      <Button variant="contained">Login with Auth0</Button>
    </Form>
  );
}
